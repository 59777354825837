<script lang="ts" setup>
import { computed, toRef } from "vue";
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { Icon } from "../../icons";
import { Shortcut, ShortcutUtil } from "../../utils/shortcuts";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import { WuxTabEmits } from "./WuxTabBar.core";

type Props = {
    id: string;
    /**
     * Values of the 'status' property are based on the Wux color variables.
     * If you update the values, make sure to update the SCSS below as well.
     */
    statusIcon?: { icon: Icon; status?: "warning" | "error" | "success" | "info" };
    /** Event emitted when a tab button is clicked. */
    onClick?: (event: MouseEvent | KeyboardEvent) => void;
    /** Disables the tab button. */
    isDisabled?: boolean;
    /** It displays the tab as focused (used for the keyboard navigation) */
    isFocused?: boolean;
    /** Set the selected state of the button. */
    isSelected?: boolean;
    /** Message for tab label. */
    labelMsg?: MessageOrId;
    /** Text for tab label. */
    label?: string;
    /** Shortcut to select the tab. */
    shortcut?: Shortcut;
};
const props = defineProps<Props>();

const { rawM } = useMsgFormatter();

const emit = defineEmits<WuxTabEmits>();

const label = computed(() => rawM(props.labelMsg, props.label));

const onClick = (e: MouseEvent | KeyboardEvent) => {
    if (props.isDisabled) return;
    props.onClick?.(e);
    emit("update:modelValue", props.id);
};

ShortcutUtil.useGlobal(toRef(props, "shortcut"), onClick);
</script>

<template>
    <div
        :tab="id"
        class="wux-tab__label"
        :class="[
            props.statusIcon?.status && `wux-tab__status-icon wux-tab__status-icon--${props.statusIcon.status}`,
            props.isDisabled && 'wux-tab--disabled',
            props.isSelected && 'wux-tab--selected',
            props.isFocused && 'wux-tab--focused',
        ]"
        @click="onClick"
    >
        <WuxIcon v-if="props.statusIcon" class="wux-tab__leading-icon" :src="props.statusIcon.icon" :size="20" />
        {{ label }}
    </div>
</template>

<style lang="scss">
.wux-tab {
    &__label {
        display: flex;
        gap: 0.5rem;
        flex-shrink: 0;
        align-items: center;
        user-select: none;
        font-size: 20px;
        padding: 0.625rem;
        scroll-snap-align: start;
        scroll-margin-left: 40px;
        &:hover {
            cursor: pointer;
            &.wux-tab--disabled {
                cursor: default;
            }
        }
    }

    &__status-icon {
        // colors defined based on our wux color variables
        &--error {
            .wux-icon {
                color: var(--wawi-color-red-500);
            }
        }
        &--warning {
            .wux-icon {
                color: var(--wawi-color-orange-500);
            }
        }
        &--success {
            .wux-icon {
                color: var(--wawi-color-green-500);
            }
        }
        &--info {
            .wux-icon {
                color: var(--wawi-color-primary-500);
            }
        }
    }

    &--selected {
        color: var(--wawi-color-primary-500);
        box-shadow: inset 0 -4px 0 -1px var(--wawi-color-primary-500);
        transition: box-shadow 0.15s linear;
    }

    &--disabled {
        color: var(--wawi-color-neutral-500);
    }

    &:focus {
        overflow: unset;
    }

    &--focused {
        outline: var(--focus-visible-outline-width--regular) solid var(--wawi-color-primary-300);
        outline-offset: var(--focus-visible-outline-offset--regular);
    }
}
</style>
