import { onMounted, onUnmounted, Ref, ref } from "vue";
import { APIError, ClientSideErrorCodes } from "@ui/clients";
import { GetRequestStateRequest, type GetRequestStateResponse } from "@ui/clients/generated/pkg/sourcing";
import { OmitOptionalDeep } from "@ui/clients/src/runtime";
import { __, ComponentUtils, MessageId, MessageOrId } from "@ui/components";
import { Dialog } from "./Dialog";
import { LOG } from "./errors";
import { SkeletonStoreLoading } from "./store";

type ErrorDialogOptions = {
    onClose?: () => void;
    showReloadButton?: boolean;
    reloadMsg?: MessageOrId;
};

const FALLBACK_MSG = __("ui.libs.errors.fallback");
const _private: { reasons: { [reason: string]: MessageId | undefined } } = {
    reasons: {
        REASON_INVALID_LOCATION: __("ui.libs.errors.reason.invalid-location"),
        REASON_OPERATION_ALREADY_RUNNING: __("ui.libs.errors.reason.operation.already-running"),
        REASON_ORACLE_UNKNOWN_LOCATION: __("ui.libs.errors.reason.oracle.unknown-location"),
        REASON_ORACLE_MISSING_LOCATION: __("ui.libs.errors.reason.oracle.missing-location"),
        REASON_ORACLE_MISSING_USER: __("ui.libs.errors.reason.oracle.missing-user"),
        REASON_ORACLE_ARCHIVER_ERROR: __("ui.libs.errors.reason.oracle.archiver-error"),
        REASON_ORACLE_CONSTRAINT_VIOLATION_CHILD_RECORDS_FOUND_ERROR: __(
            "ui.libs.errors.reason.oracle.constraint-violation.child-records-found",
        ),
        REASON_ORACLE_UNAVAILABLE: __("ui.libs.errors.reason.oracle.unavailable"),
        REASON_SETTING_MISSING: __("ui.libs.errors.reason.setting.missing"),
        REASON_SETTING_CANNOT_PARSE: __("ui.libs.errors.reason.setting.cannot-parse"),
        REASON_ORACLE_UNIQUE_CONSTRAINT_VIOLATION: __("ui.libs.errors.reason.oracle.unique-constraint-violation"),
        REASON_ORACLE_ACCOUNT_LOCKED: __("ui.libs.errors.reason.oracle.database-account-locked"),
        REASON_ORACLE_INVALID_USERNAME_PASSWORD: __("ui.libs.errors.reason.oracle.invalid-username-password"),
        REASON_ORACLE_OUT_OF_MEMORY: __("ui.libs.errors.reason.oracle.out-of-memory"),
        REASON_ORACLE_DEADLINE_EXCEEDED: __("ui.libs.errors.reason.oracle.deadline-exceeded"),
        REASON_WAIT_FOR_REQUEST_TIMEOUT: __("ui.libs.errors.reason.wait-for-request-timeout"),
    },
};

const showErrorDialog = (contentMsg: MessageOrId, options?: ErrorDialogOptions) =>
    Dialog.showError({
        contentMsg,
        ...options,
    });

const HTTP_STATUS_ERROR_MESSAGES: { [key: number]: MessageId | undefined } = {
    400: __("ui.libs.errors.400"),
    403: __("ui.libs.errors.403"),
    404: __("ui.libs.errors.404"),
    409: __("ui.libs.errors.409"),
    500: __("ui.libs.errors.500"),
    503: __("ui.libs.errors.503"),
};

const _getErrorMsg = (err: APIError): MessageOrId => {
    const reasonMsgId = err.reason && _private.reasons[err.reason];
    if (reasonMsgId) {
        // ATTENTION: "message" could be overridden if the key exists in "metadata"
        return { id: reasonMsgId, values: { message: err.message, ...err.metadata } };
    }
    return {
        id: HTTP_STATUS_ERROR_MESSAGES[err.code] || FALLBACK_MSG,
        values: {
            message: [err.message, ...err.fieldViolations.map((f) => `"${f.field}" : ${f.description}`)].join("\n"),
        },
    };
};

const catchError = (err: unknown, options?: ErrorDialogOptions, prefix?: string): undefined => {
    if (err instanceof APIError) {
        if (err.code === ClientSideErrorCodes.ABORT || err.code === ClientSideErrorCodes.OFFLINE) {
            options?.onClose?.();
            return undefined;
        }

        showErrorDialog(_getErrorMsg(err), options);
        // Note: Logging timeout errors to DD helps us identify cases where the UI cancels requests due to timeouts.
        // Some timeouts are caused by database deadlocks. So when the UI aborts a request after a timeout, the database gets a cancel signal and we miss catching the deadlock issue.
        if (
            err.code === ClientSideErrorCodes.UNEXPECTED ||
            err.code === ClientSideErrorCodes.PARSING ||
            err.code === ClientSideErrorCodes.TIMEOUT ||
            err.code === 403 // Permission denied
        ) {
            LOG.caught(err, prefix);
        }
    } else {
        LOG.caught(err, prefix);
        showErrorDialog(FALLBACK_MSG, options);
    }

    // we explicitly return here "undefined" because this will make further handling of not-returned responses easier
    return undefined;
};
const handleError = (err: unknown, options?: ErrorDialogOptions) => {
    catchError(err, options);
    // confirmation modals catch those errors themselves and have different error handling
    throw err;
};

const catchErrorExceptAborted = (err: unknown, options?: ErrorDialogOptions, prefix?: string) => {
    if (isAbortedError(err)) {
        throw err;
    } else {
        return catchError(err, options, prefix);
    }
};

const isAbortedError = (err: unknown): boolean => {
    if (err instanceof APIError && err.code === ClientSideErrorCodes.ABORT) {
        return true;
    }
    return false;
};

// this function does nothing
const catchSilently = (_err: unknown) => undefined;

// all configured reasons will be matched if applicable with higher priority than the default HTTP error messages,
// and they will be provided the metadata as dynamic values
const configureReasons = (reasons: { [reason: string]: MessageId }) => {
    _private.reasons = { ..._private.reasons, ...reasons };
};

const DEFAULT_PAGE_SIZE = 50n;
const MAX_ITEMS_MULTIPLIER = 10n;

type ListFunc = (options: { pageToken: string; pageSize: bigint; [k: string]: unknown }) => Promise<{
    nextPageToken: string;
    [k: string]: unknown;
}>;
type Opts<TListFunc extends ListFunc> = {
    listFunc: TListFunc;
    onLoad: (response: Awaited<ReturnType<TListFunc>>) => void | Promise<void>;
    itemsFieldKey: keyof Awaited<ReturnType<TListFunc>>;
    onFail?: (error: unknown) => void;
    pageSize?: bigint;
    maximumLoadedItems?: bigint;
};
/**
 * Usage:
 *
 * In Services/Service.ts file:
 *
 * const dummiesLoader = ServiceUtils.createLoader<typeof Client.loadDummies, { foo: string }>({
 *   listFunc: (opts) => Client.loadDummies(opts),
 *   onLoad: (response) => {
 *       Store.dummy.values.push(...response.dummies);
 *   },
 *   itemsFieldKey: "dummies",
 *   onFail: (e) => {
 *      // handle error
 *   }
 *   pageSize: 20n,
 *   maximumLoadedItems: 100n,
 * });
 *
 * export const Service { dummiesLoader };
 *
 * In Vue files:
 *
 * Service.dummiesLoader.loadFunc({ otherOpts: { foo: "bar" } });
 * ...
 * <template>
 *   <div v-if="dummiesLoader.loading">Loading...</div>
 * </template>
 */
const createLoader = <TListFunc extends ListFunc, TOpts extends Record<string, unknown>>({
    listFunc,
    onLoad,
    itemsFieldKey,
    onFail,
    pageSize = DEFAULT_PAGE_SIZE,
    maximumLoadedItems = pageSize * MAX_ITEMS_MULTIPLIER,
}: Opts<TListFunc>) => {
    let version = 0;
    const loading = ref(false);
    const nextPageToken = ref("");
    return {
        loading,
        nextPageToken,
        loadFunc: async (opts: { startingPageToken?: string } & Partial<TOpts> = {}) => {
            const { startingPageToken = "", ...otherOpts } = opts;
            const internalVersion = Date.now();
            version = internalVersion;
            loading.value = true;
            let currentNextPageToken = startingPageToken;
            try {
                let loadedItems = 0;
                do {
                    if (loadedItems >= maximumLoadedItems) {
                        break;
                    }

                    const response = await listFunc({ ...otherOpts, pageSize, pageToken: currentNextPageToken });

                    // Only update when this is still the last operation that was triggered
                    if (version !== internalVersion) {
                        break;
                    }
                    await onLoad(response as Awaited<ReturnType<TListFunc>>);
                    const items = response[itemsFieldKey as keyof typeof response] as unknown[];
                    loadedItems += items.length;
                    currentNextPageToken = response.nextPageToken;
                } while (currentNextPageToken);
                nextPageToken.value = currentNextPageToken;
            } catch (e) {
                catchError(e);
                onFail?.(e);
                nextPageToken.value = "";
                return;
            } finally {
                loading.value = false;
            }
        },
        reset: () => {
            nextPageToken.value = "";
            loading.value = false;
        },
    };
};

type PollingParams<TValue, WithInitialValue extends boolean> = {
    poll: (
        controller: AbortController,
        lastValue: WithInitialValue extends true ? TValue : TValue | undefined,
    ) => Promise<TValue>;
    isFinished?: (result: WithInitialValue extends true ? TValue : TValue | undefined) => boolean;
    pollingIntervalMs?: number;
    delayMs?: number;
    shouldPollInHiddenTabs?: boolean;
} & (WithInitialValue extends true
    ? { getInitialValue: (controller: AbortController) => TValue | Promise<TValue> }
    : { getInitialValue?: undefined });

const DEFAULT_POLLING_INTERVAL_MS = 5000;

/**
 * Given a function to call periodically, provides an automatically updating ref to the result.
 * All setup and cleanup of the polling interval is handled for you.
 *
 * @param poll Function to be periodically called to update the value of the result.
 * The first parameter of `poll` is an AbortController and the second is the last
 * value of the result
 * @param getInitialValue A function to determine the initial value for the result.
 * The first parameter of `getInitialValue` is an AbortController. If `getInitialValue` always returns
 * a defined value, we will narrow all types to correctly eliminate `undefined` as an option.
 * If the function throws an error, it won't be catched, and the polling operation won't start.
 * @param isFinished A function to check for when to stop polling. By default, poll indefinitely
 * @param pollingIntervalMs How long to wait between polling attempts. Defaults to 5000
 * @param delayMs How long to wait before the initial polling / `initialFetch`. Defaults to no delay
 * @param shouldPollInHiddenTabs Whether the polling function should be called when
 * the tab/window is not visible (e.g. minimized or another tab is open in the window).
 * Defaults to false
 *
 * @returns A ref to the result. If an `getInitialValue` is provided, then the ref
 * will always contain a valid result. Otherwise, it will start `undefined`
 */
const usePolling: {
    <TValue>(params: PollingParams<TValue, false>): Ref<TValue | undefined>;
    <TValue>(params: PollingParams<TValue, true>): Ref<TValue>;
} = <TValue>(params: PollingParams<TValue, boolean>) => {
    const controller = new AbortController();
    const result = ref<TValue>();

    const doPolling = async () => {
        do {
            try {
                // We use `document.hidden` instead of `window.onblur`/`.onfocus` because we want to
                // poll in tabs which are not focused but are visible (e.g. on second monitor)
                // See https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API for details
                if (params.shouldPollInHiddenTabs || !document.hidden)
                    result.value = await params.poll(controller, result.value);
            } catch (e) {
                // If an uncaught error occurs while polling, ignore it and continue
                ServiceUtils.catchSilently(e);
            }
            await ComponentUtils.sleep(params.pollingIntervalMs ?? DEFAULT_POLLING_INTERVAL_MS);
        } while (!params.isFinished?.(result.value) && !controller.signal.aborted);
    };

    onMounted(async () => {
        if (params.delayMs) await ComponentUtils.sleep(params.delayMs);
        // We don't do any error handling on purpose for `getInitialValue`
        if (params.getInitialValue) result.value = await params.getInitialValue(controller);
        doPolling();
    });

    onUnmounted(() => {
        controller.abort(); // This will also stop the do...while loop in `doPolling`
    });

    return result;
};

/**
 * Configuration options for `waitForRequest`
 * @property {number} timeoutMs Maximum waiting time (in milliseconds)
 * @property {number} pollingIntervalMs Waiting time between subsequent requests (in milliseconds)
 * @property {boolean} noLoadingIndicator Disables the loading indicator when fetching the request state
 */
export type WaitForRequestOptions = Partial<{
    pollingIntervalMs: number;
    timeoutMs: number;
    noLoadingIndicator: boolean;
}>;

const waitForRequestDefaultOptions = {
    pollingIntervalMs: 300,
    timeoutMs: 12_000,
};

type GetRequestStateQueryParams = OmitOptionalDeep<GetRequestStateRequest, "">;

/**
 * To be used with event sourced endpoints based on `pkg/sourcing`.
 * Periodically calls the given GetRequestState function until the request is done
 * or the given timeout is reached. Throws an error if the request failed, which can be
 * handled using `ServiceUtils.catchError`.
 *
 * @param request Request object
 * @param getRequestStateFunc function to get the request state, typically defined in a client file
 * @param options Configuration options
 *
 * @returns true is the request completed successfully
 */
const waitForRequest = async (
    request: GetRequestStateRequest,
    getRequestStateFunc: (params: GetRequestStateQueryParams, signal?: AbortSignal) => Promise<GetRequestStateResponse>,
    options?: WaitForRequestOptions,
): Promise<boolean> => {
    if (!options?.noLoadingIndicator) {
        // Keep loading indicator while polling
        // This prevents the loading indicator from flickering every time we check the request state
        SkeletonStoreLoading.start();
    }
    const controller = new AbortController();
    let pollIntervalId: NodeJS.Timeout;
    const pollPromise = new Promise<boolean>((resolve, reject) => {
        pollIntervalId = setInterval(async () => {
            const result = await getRequestStateFunc(
                {
                    requestId: request.requestId,
                    requestType: request.requestType,
                },
                controller.signal,
            ).catch(catchSilently); // ignore fetch errors
            if (result?.state.done) {
                if (!result.state.failed) {
                    resolve(true);
                } else {
                    reject(result.state.error);
                }
            }
        }, options?.pollingIntervalMs ?? waitForRequestDefaultOptions.pollingIntervalMs);
    });

    // Create APIError that can be parsed using catchError
    const timeoutError = new APIError(ClientSideErrorCodes.TIMEOUT, "TIMEOUT", {
        details: [
            {
                "@type": `type.googleapis.com/google.rpc.ErrorInfo`,
                reason: "REASON_WAIT_FOR_REQUEST_TIMEOUT",
            } as { "@type": any },
        ],
    });
    let timeoutId: NodeJS.Timeout;
    const timeoutPromise = new Promise<boolean>((_, reject) => {
        timeoutId = setTimeout(() => {
            reject(timeoutError);
        }, options?.timeoutMs ?? waitForRequestDefaultOptions.timeoutMs);
    });

    return Promise.race([pollPromise, timeoutPromise]).finally(() => {
        clearInterval(pollIntervalId);
        clearTimeout(timeoutId);
        controller.abort(); // cancel all pending requests
        if (!options?.noLoadingIndicator) {
            SkeletonStoreLoading.end();
        }
    });
};

const handleLimitHit = <T>({ results, nextPageToken }: { results: T[]; nextPageToken?: string }): T[] => {
    if (nextPageToken) {
        LOG.errorMsg("FETCH_LIMIT_REACHED");
        showErrorDialog(__("ui.libs.skeleton.more-data-to-fetch"));
    }
    return results;
};

export const ServiceUtils = {
    catchError,
    catchErrorExceptAborted,
    isAbortedError,
    handleError,
    catchSilently,
    configureReasons,
    createLoader,
    usePolling,
    waitForRequest,
    handleLimitHit,
};
