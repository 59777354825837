<script setup lang="ts">
import { computed, ref, useTemplateRef } from "vue";
import { useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import { Shortcuts } from "../../utils/shortcuts";
import { TreeListItem, WuxTreeListProps } from "./WuxTreeList.core";
import WuxTreeListItem from "./WuxTreeListItem.vue";

const { rawM } = useMsgFormatter();

const treeList = useTemplateRef("treeList");

const props = defineProps<WuxTreeListProps>();
const emit = defineEmits<{
    /** Emitted whenever an item (without children) is selected */
    select: [item: TreeListItem];
}>();

const activeChild = ref<TreeListItem | null>(null);
const activeElements = computed<TreeListItem[]>(() => {
    const isSelected = (item: TreeListItem) => item.isSelected || !!item.items?.some(isSelected);

    const items = activeChild.value
        ? [
              // First element representing the back button
              {
                  label: activeChild.value.label,
                  labelMsg: activeChild.value.labelMsg,
                  description: activeChild.value.description,
                  descriptionMsg: activeChild.value.descriptionMsg,
                  icon: Icons.chevron_left_small,
                  shortcut: Shortcuts.Backspace.alt,
              },
              ...(activeChild.value?.items ?? props.items),
          ]
        : props.items;

    return items.map((item) => ({ ...item, isSelected: isSelected(item) }));
});

const whichClick = ref<"click" | "back">(); //used to decide which animation to show

const handleItemClick = (e: MouseEvent | KeyboardEvent, item: TreeListItem) => {
    // If there's an active child & the first item was clicked it's the back button
    if (activeChild.value && item === activeElements.value[0]) {
        whichClick.value = "back";
        const previousElement = activeChild.value;
        activeChild.value = pastElements.value.pop() ?? null;
        focusedElement.value =
            activeElements.value.findIndex(
                (elem) => rawM(elem.labelMsg, elem.label) === rawM(previousElement.labelMsg, previousElement.label),
            ) ?? 0;
        return;
    }

    // If the clicked item has children, display them
    if (item.items) {
        whichClick.value = "click";
        focusedElement.value = 0;
        pastElements.value.push(activeChild.value);
        activeChild.value = item;
        return;
    }

    focusedElement.value = -1;
    pastElements.value = [];
    activeChild.value = null;
    emit("select", item);
    item.onClick?.(e);
};

const focusedElement = ref<number>(-1);
const pastElements = ref<(TreeListItem | null)[]>([]);

const treeItemsRef = ref<Record<number, InstanceType<typeof WuxTreeListItem> | undefined>>({});
const registerTreeItem = (element: InstanceType<typeof WuxTreeListItem> | null, index: number) => {
    if (element) treeItemsRef.value[index] = element;
    else delete treeItemsRef.value[index];
};

const onArrowDown = () => {
    if (!activeElements.value.length || !activeElements.value.some((e) => !e.disabledMsg && !e.isLoading)) return;

    let nextElementIndex = focusedElement.value !== -1 ? (focusedElement.value + 1) % activeElements.value.length : 0;

    while (activeElements.value[nextElementIndex].disabledMsg || activeElements.value[nextElementIndex].isLoading) {
        nextElementIndex = (nextElementIndex + 1) % activeElements.value.length;
    }
    focusedElement.value = nextElementIndex;
    scrollIntoFocusedItem(nextElementIndex);
};

const onArrowUp = () => {
    if (!activeElements.value.length || !activeElements.value.some((e) => !e.disabledMsg && !e.isLoading)) return;

    let prevElementIndex = focusedElement.value > 0 ? focusedElement.value - 1 : activeElements.value.length - 1;

    while (activeElements.value[prevElementIndex].disabledMsg || activeElements.value[prevElementIndex].isLoading) {
        prevElementIndex = prevElementIndex === 0 ? activeElements.value.length - 1 : prevElementIndex - 1;
    }
    focusedElement.value = prevElementIndex;
    scrollIntoFocusedItem(prevElementIndex);
};

const scrollIntoFocusedItem = (index: number) => {
    treeItemsRef.value[index]?.focus();
};

const createItemKey = (item: TreeListItem) =>
    `${rawM(item.labelMsg, item.label) ?? ""}${rawM(item.descriptionMsg, item.description) ?? ""}${item.items?.length}`;

defineExpose({
    focus: (options?: { preventScroll?: boolean }) => treeList.value?.focus({ preventScroll: options?.preventScroll }),
});
</script>

<template>
    <div
        ref="treeList"
        class="wux-tree-list"
        tabindex="-1"
        @keydown.up.prevent.stop="onArrowUp"
        @keydown.down.prevent.stop="onArrowDown"
    >
        <div class="wux-tree-items">
            <Transition
                :name="`wux-tree-items__transition--${whichClick}`"
                @afterEnter="scrollIntoFocusedItem(focusedElement)"
            >
                <div :key="activeChild ? createItemKey(activeChild) : 'root'">
                    <WuxTreeListItem
                        v-for="(item, i) in activeElements"
                        :ref="(el) => registerTreeItem(el as InstanceType<typeof WuxTreeListItem> | null, i)"
                        :key="i"
                        :class="{ 'wux-tree-list__back-button': activeChild && i === 0 }"
                        :href="item.href"
                        :icon="item.icon"
                        :shortcut="item.shortcut"
                        :disabledMsg="item.disabledMsg"
                        :description="item.description"
                        :descriptionMsg="item.descriptionMsg"
                        :label="item.label"
                        :labelMsg="item.labelMsg"
                        :items="item.items"
                        :isSelected="item.isSelected"
                        :isLoading="item.isLoading"
                        :hasShortcutsEnabled="props.hasShortcutsEnabled"
                        @select="handleItemClick($event, item)"
                    />
                </div>
            </Transition>
        </div>
    </div>
</template>

<style lang="scss">
.wux-tree-list {
    min-width: 100%;
    max-width: 40rem;
    &:focus {
        outline: none;
    }
}

.wux-tree-items {
    overflow: hidden;
    --transition-time: 0.1s;

    &__transition {
        &--click-enter-active,
        &--back-enter-active {
            transition: all var(--transition-time) ease-in-out;
            pointer-events: none;
        }

        &--click-leave-active,
        &--back-leave-active {
            display: none;
            pointer-events: none;
        }

        &--click-enter-from {
            transform: translateX(100%);
        }
        &--click-enter-to {
            transform: translateX(0%);
        }

        &--back-enter-from {
            transform: translateX(-100%);
        }
        &--back-enter-to {
            transform: translateX(0%);
        }
    }
}
</style>
