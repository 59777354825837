import { Fetch } from "./fetch";
import { Endpoint, HTTPMethod } from "./runtime";

type GenericListResponse = {
    nextPageToken?: string;
};

type GenericListParams = {
    pageSize: bigint;
    pageToken: string;
};

type FetchAllPages = {
    <TResponse extends GenericListResponse, TEntry, TParams extends GenericListParams>(config: {
        endpoint: Endpoint<TResponse, TParams, void, void>;
        extract: (r: TResponse) => TEntry[];
        body: Omit<TParams, "pageSize" | "pageToken">;
        queryParams?: never;
        limit: bigint;
        pageToken?: string;
        pageSize?: bigint;
        signal?: AbortSignal;
    }): Promise<{
        results: TEntry[];
        nextPageToken?: string;
    }>;
    <TResponse extends GenericListResponse, TEntry, TParams extends GenericListParams>(config: {
        endpoint: Endpoint<TResponse, void, void, TParams>;
        extract: (r: TResponse) => TEntry[];
        body?: never;
        queryParams: Omit<TParams, "pageSize" | "pageToken">;
        limit: bigint;
        pageToken?: string;
        pageSize?: bigint;
        signal?: AbortSignal;
    }): Promise<{
        results: TEntry[];
        nextPageToken?: string;
    }>;
};

export const fetchAllPages: FetchAllPages = ({
    endpoint,
    extract,
    body,
    queryParams,
    pageToken,
    pageSize = 100n,
    limit,
    signal,
}) =>
    fetchPageRecursive({
        endpoint,
        extract,
        pageSize,
        pageToken,
        alreadyFetchedData: [],
        body,
        queryParams,
        limit,
        signal,
    });

const fetchPageRecursive = <TResponse extends GenericListResponse, TEntry, TParams extends GenericListParams>({
    endpoint,
    extract,
    pageSize,
    pageToken = "",
    alreadyFetchedData,
    body,
    queryParams,
    limit,
    signal,
}: {
    endpoint: Endpoint<TResponse, TParams, void, void> | Endpoint<TResponse, void, void, TParams>;
    extract: (r: TResponse) => TEntry[];
    pageSize: bigint;
    pageToken?: string;
    alreadyFetchedData: TEntry[];
    body?: Omit<TParams, "pageSize" | "pageToken">;
    queryParams?: Omit<TParams, "pageSize" | "pageToken">;
    limit: bigint;
    signal?: AbortSignal;
}): Promise<{
    results: TEntry[];
    nextPageToken?: string;
}> =>
    (endpoint.method === HTTPMethod.GET
        ? Fetch.fetchParsedResponse({
              endpoint: endpoint as Endpoint<TResponse, void, void, TParams>,
              queryParams: { pageToken, pageSize, ...queryParams },
              signal,
          })
        : Fetch.fetchParsedResponse({
              endpoint,
              body: { pageToken, pageSize, ...body },
              signal,
          })
    ).then((response) => {
        alreadyFetchedData.push(...extract(response));
        if (response.nextPageToken && alreadyFetchedData.length < limit) {
            return fetchPageRecursive({
                endpoint,
                extract,
                pageSize,
                pageToken: response.nextPageToken,
                alreadyFetchedData,
                body,
                queryParams,
                limit,
            });
        }
        return { results: alreadyFetchedData, nextPageToken: response.nextPageToken };
    });
