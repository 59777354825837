import { __ } from "@ui/components";
import { Dialog, ServiceUtils } from "../skeleton";
import { LOG } from "../skeleton/errors";
import { CSV } from "./csv";
import { DateUtil } from "./date";
import { FileSystemUtil } from "./file-system";

const pathJoin = (firstPart: string, ...pathFragments: Array<string | undefined>) => {
    const joined = pathFragments.filter(Boolean).join("/").replaceAll(/\/+/g, "/");
    return `${firstPart.replace(/\/$/, "")}/${joined.replace(/^\//, "")}`;
};

/**
 *
 * Convert a bigint to a number with a check if the bigint is outside of the safe integer boundaries.
 * It will log an error if the conversion is unsafe, so that these cases can be tracked.
 * If force is `false` (default) then it will return `NaN` in case of an unsafe conversion. Otherwise it will still do perform the conversion.
 *
 * @param bi bigint to convert to a number
 * @param force will perform conversion even if unsafe to do so (default: false)
 */
const unsafeBigIntToNumber = (bi: bigint, force = false): number => {
    if (bi > Number.MAX_SAFE_INTEGER || Number.MIN_SAFE_INTEGER > bi) {
        LOG.errorMsg(
            `BigInt to Number Conversion Error: BigInt ${bi} is outside of safe integer boundaries and will be inaccurate.`,
        );
        if (!force) {
            return NaN;
        }
    }
    return Number(bi);
};

export type CsvRow = string[];
/**
 * Works perfectly for Excel, but note that this may not be supported in other spreadsheet or data programs.
 * It may only be a small issue, simply showing an extra first line with sep= or sep=; in it,
 * but some automated programs or scripts may not expect the extra first line,
 * resulting in the column headers or the sep=; line itself being interpreted as actual data.
 */
const toCSV = (headers: CsvRow, rows: CsvRow[], delimiter = ";"): string =>
    CSV.encode(rows, { headers, delimiter, newline: "\n" });

const fromCSV = (csvText: string, delimiter = ";"): CsvRow[] => CSV.decode(csvText, { delimiter, newline: "\n" });

const downloadCSV = async (filename: string, csv: string) =>
    downloadFile(filename, csv, "text/csv;charset=utf-8", ".csv", true);

const downloadFile = async (
    filename: string,
    data: BufferSource | Blob | string,
    contentType: string,
    fileTypeSuffix: `.${string}`,
    displayFilePicker = false,
) => {
    try {
        const handle =
            displayFilePicker && (await FileSystemUtil.requestFileHandle(filename, contentType, fileTypeSuffix));
        if (handle === "canceled") return; // Don't bother downloading at all if user canceled (and don't fallback either)
        if (handle) {
            // Write the blob to the file.
            const writable = await handle.createWritable();
            if (fileTypeSuffix === ".csv") {
                // Prepend CSV with a Byte Order Mark
                // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
                // it is also needed in when exporting a CSV in Windows.
                // https://stackoverflow.com/questions/6588068/which-encoding-opens-csv-files-correctly-with-excel-on-both-mac-and-windows
                // Hint: In case the buffer already included these characters and we write them twice it will not be differently shown
                await writable.write("\ufeff");
            }
            await writable.write(data);
            await writable.close();
            return;
        }
        // Fallback if the File System Access API is not supported…
        // Create the blob URL.
        const content = fileTypeSuffix === ".csv" ? ["\ufeff", data] : [data];
        const blob = new Blob(content, { type: contentType });
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        // click the link in order to start download
        a.click();
        // free app resources in the browser
        // https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } catch (e) {
        if (
            e instanceof Error &&
            e.message.includes(
                "An operation that depends on state cached in an interface object was made but the state had changed since it was read from disk",
            )
        ) {
            Dialog.showError({
                headerMsg: __("ui.components.dialog.disk-space-error.title"),
                contentMsg: __("ui.components.dialog.disk-space-error.content"),
            });
            return;
        }
        ServiceUtils.catchError(e);
    }
};

/**
 * Function to open a blob (e.g. a file) inside a new tab.
 * @param blob the file to open in a new tab.
 */
const openFile = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    const win = window.open(url, "_blank");

    if (!win) return;
    // Keep track of the opened window and free the resources allocated for the opened blob once it's closed
    const revokeUrlInterval = setInterval(() => {
        if (!win.closed) return;

        // free app resources in the browser
        // https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
        URL.revokeObjectURL(url);
        clearInterval(revokeUrlInterval);
    }, 10_000);
};

export const Utils = {
    pathJoin,
    toCSV,
    fromCSV,
    downloadCSV,
    downloadFile,
    openFile,
    unsafeBigIntToNumber,
    date: DateUtil,
};
