import { computed } from "vue";
import { createIndexedDBStore } from "./indexed-db";

const INDEXEDDB_KEY = "FILE_SYSTEM_HANDLES";
const fileSystemHandleStore = createIndexedDBStore<Record<string, FileSystemDirectoryHandle | undefined>>(
    INDEXEDDB_KEY,
    // System tests mock the return value of `window.showDirectoryPicker` with an unserializable value
    { ignoreErrorsOnSet: typeof Cypress !== "undefined" },
);

const _reset = () => fileSystemHandleStore.delete();

// Feature detection. The API needs to be supported
// and the app not run in an iframe (cypress).
const isAPISupported = (type: "file" | "directory") =>
    (type === "file" ? "showSaveFilePicker" : "showDirectoryPicker") in window &&
    (() => {
        try {
            return window.self === window.top;
        } catch {
            return false;
        }
    })();

const _requestPermission = async (handle: FileSystemDirectoryHandle, mode: "read" | "readwrite") =>
    (await handle.queryPermission({ mode })) === "granted" || (await handle.requestPermission({ mode })) === "granted";

const requestFileHandle = async (filename: string, contentType: string, fileTypeSuffix: string) => {
    if (!isAPISupported("file")) return undefined;

    // remove potentially existing charset or other extra from contentType
    const mimeType = contentType.split(";")[0];
    try {
        return await showSaveFilePicker({
            suggestedName: `${filename}${fileTypeSuffix}`,
            types: [
                {
                    // description should be specified, otherwise the filepicker would fail to open in some cases
                    description: fileTypeSuffix.substring(1).toUpperCase(),
                    accept: { [mimeType as `${string}/${string}`]: [fileTypeSuffix as `.${string}`] },
                },
            ],
        });
    } catch (err) {
        // Report if the user has canceled the dialog.
        if ((err as Error).name === "AbortError") return "canceled";
        else throw err;
    }
};

const requestNewDirectoryHandle = async (directoryId: string, mode: "read" | "readwrite") => {
    if (!isAPISupported("directory")) return undefined;

    try {
        const newHandle = await showDirectoryPicker({ mode });
        fileSystemHandleStore.set({ [directoryId]: newHandle });
        return newHandle;
    } catch (err) {
        // Report if the user has canceled the dialog.
        if ((err as Error).name === "AbortError") return "canceled";
        else throw err;
    }
};
// We don't have access to ComponentUtils.promiseWithResolvers here in ui/libs/skeleton
const promiseWithResolvers = <T>(): PromiseWithResolvers<T> => {
    let resolve: (value: T | PromiseLike<T>) => void = () => {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: ((reason?: any) => void) | undefined = () => {};
    const promise = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return { promise, resolve, reject };
};

const requestDirectoryHandle = async (directoryId: string, mode: "read" | "readwrite") => {
    const { promise, resolve } = promiseWithResolvers<FileSystemDirectoryHandle | "canceled" | undefined>();

    const handle = (await fileSystemHandleStore.get())?.[directoryId];

    // GEJ: This _mostly_ addresses a rare and subtle problem with preserving user gesture context between microtasks:
    // In order for the call to `await showDirectoryPicker` in `requestNewDirectoryHandle` to be allowed by the
    // browser, it must follow from a user gesture (e.g. click). Unfortunately, we need to first try and get the
    // existing handle from the `fileSystemHandleStore`. Getting the handle from the store and the code below are part
    // of two separate microtasks, so it is possible in rare situations for them to be scheduled in such a way that
    // the user gesture context is lost by the time the second microtask runs. By using `queueMicrotask`, we ensure
    // that the second microtask runs immediately after the first one.
    //
    // Note: I believe it is still possible for the user gesture context to be lost while getting the handle from the
    // store, since the underlying `idb-keyval` library wraps the `IDBRequest` into a promise. But this should still
    // fix a few cases
    queueMicrotask(async () => {
        if (handle && (await Helpers._requestPermission(handle, mode))) {
            resolve(handle);
        } else {
            resolve(await requestNewDirectoryHandle(directoryId, mode));
        }
    });

    return promise;
};

const getDirectoryName = async (directoryId: string) => (await fileSystemHandleStore.get())?.[directoryId]?.name;

const useDirectoryName = (directoryId: string) => {
    const handles = fileSystemHandleStore.use();

    return computed(() => handles.value[directoryId]?.name);
};

export const FileSystemUtil = {
    requestFileHandle,
    requestNewDirectoryHandle,
    requestDirectoryHandle,
    getDirectoryName,
    useDirectoryName,
};

export const Helpers = {
    _requestPermission,
    _reset,
};
